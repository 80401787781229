<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import Swal from "sweetalert2";

import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";
import axios from "axios";
import animationData1 from "@/components/widgets/gsqxdxog.json";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Fontcolor from "@ckeditor/ckeditor5-font/src/fontcolor";
import Fontsize from "@ckeditor/ckeditor5-font/src/fontsize";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import Todolist from "@ckeditor/ckeditor5-list/src/todolist";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";

import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableColumnResize from "@ckeditor/ckeditor5-table/src/tablecolumnresize";
import TableCaption from "@ckeditor/ckeditor5-table/src/tablecaption";

import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";

export default {
  page: {
    title: "部落格文章",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "部落格文章",
      items: [
        {
          text: "頁面管理",
          href: "/",
        },
        {
          text: "部落格文章",
          active: true,
        },
      ],
      sortby: null,
      img_url: process.env.VUE_APP_IMG_URL,

      // 資料
      data: [],
      page: 1,
      perPage: 10,
      pages: [],
      searchQuery: null,

      temp_data: {},
      category_data: [],
      article_data: [],
      tag_data: [],

      defaultOptions: {
        animationData: animationData,
      },
      defaultOptions1: {
        animationData: animationData1,
      },

      // error handle
      submitted: false,
      result_msg_variant: "danger",
      result_msg: null,
      form_errors: {},

      // editor
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Essentials,
          Autoformat,
          FontBackgroundColor,
          Fontcolor,
          Fontsize,
          FontFamily,
          Bold,
          Italic,
          Underline,
          Strikethrough,
          Superscript,
          Subscript,
          BlockQuote,
          Heading,
          SourceEditing,

          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageResize,
          LinkImage,
          Link,
          List,
          Todolist,
          Paragraph,
          Alignment,
          Indent,
          CodeBlock,
          Code,
          Table,
          TableToolbar,
          TableCaption,
          TableProperties,
          TableCellProperties,
          TableColumnResize,
          ImageInsert,
          SimpleUploadAdapter,
        ],

        toolbar: {
          items: [
            "heading",
            {
              label: "Font styles",
              icon: "text",
              items: [
                "fontfamily",
                "fontsize",
                "fontcolor",
                "fontbackgroundcolor",
              ],
            },
            {
              label: "Basic styles",
              icon: "bold",
              items: [
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "superscript",
                "subscript",
              ],
            },
            {
              label: "Lists",
              withText: true,
              items: [
                "bulletedList",
                "numberedList",
                "todoList",
                "|",
                "outdent",
                "indent",
              ],
            },
            "|",
            "alignment",
            "link",
            "|",
            "code",
            "codeBlock",
            'sourceEditing',
            "|",
            "insertTable",
            "blockQuote",
            {
              label: "insertImage",
              icon: "plus",
              items: [
                "insertImage",
                "imageStyle:block",
                "imageStyle:side",
                "|",
                "toggleImageCaption",
                "imageTextAlternative",
                "|",
                "linkImage",
                "uploadimage",
              ],
            },
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
            "toggleTableCaption",
          ],
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: "https://api.xin-cheng.tech/test/image_upload",

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            // "X-CSRF-TOKEN": "CSRF-Token",
            // Authorization: "Bearer <JSON Web Token>",
          },
        },
      },
    };
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.data);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        let result = this.displayedPosts.filter((data) => {
          return (
            data.title.toLowerCase().includes(search) ||
            data.updated_at.toLowerCase().includes(search) ||
            data.created_at.toLowerCase().includes(search)
          );
        });

        return result;
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    data() {
      this.setPages();
    },
    sortby() {
      if (this.sortby) {
        this.sortArraysByKey(this.data, this.sortby);
      } else {
        this.sortArraysByKey(this.data, "id");
      }
    },
  },
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    sortArraysByKey(arrays, key) {
      arrays.sort((a, b) => (a[key] > b[key] ? 1 : -1));
    },
    editModal(itemData) {
      document.getElementById("editModalLabel").innerHTML = "修改部落格文章";
      this.temp_data = itemData;
      this.submitted = false;
      this.temp_data.category_id = itemData.category.id;
      this.temp_data.category.name = itemData.category.name;

      let tag_array = itemData.tags.map((i) => i.id);
      this.temp_data.tag_selected = tag_array;

      document.getElementById("edit-btn").style.display = "block";
      document.getElementById("add-btn").style.display = "none";
    },
    updateData() {
      this.submitted = true;

      let formData = new FormData();
      formData.append("title", this.temp_data.title || "");
      formData.append("url_name", this.temp_data.url_name || "");
      formData.append("content", this.temp_data.content || "");
      formData.append(
        "meta_description",
        this.temp_data.meta_description || ""
      );
      formData.append(
        "tags",
        JSON.stringify(this.temp_data.tag_selected) || ""
      );
      formData.append("category_id", parseInt(this.temp_data.category_id));
      formData.append(
        "pre_article_id",
        parseInt(this.temp_data.pre_article_id)
      );
      formData.append(
        "next_article_id",
        parseInt(this.temp_data.next_article_id)
      );
      let photo = document.getElementById("image").files[0];
      if (photo) {
        formData.append("image", photo);
      }

      axios
        .put(
          `${process.env.VUE_APP_BACKEND_URL}article/${this.temp_data.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((result) => {
          if (result.data.status == 0) {
            this.closeModal();
            return (this.result_msg = result.data.msg);
          }
          let i = this.data.findIndex((o) => o.id == this.temp_data.id);
          this.data[i] = result.data.data;
          this.temp_data = {};
          this.result_msg = result.data.msg;
          this.result_msg_variant = "success";
          this.submitted = false;
          this.closeModal();
        })
        .catch((error) => {
          this.result_msg_variant = "danger";
          this.result_msg = error.response.data.msg;
          this.form_errors = error.response.data.errors;
        });
    },
    deletedata(itemData) {
      Swal.fire({
        title: "確認刪除?",
        text: "刪除後無法再復原資料!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "確認刪除!",
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(`${process.env.VUE_APP_BACKEND_URL}article/${itemData.id}`)
            .then((result) => {
              if (result.data.status == 1) {
                Swal.fire(
                  "成功刪除!",
                  `${result.data.data.title} 已刪除`,
                  "success"
                );
                this.data.splice(this.data.indexOf(itemData), 1);
                return;
              }
              Swal.fire("刪除失敗!", `${result.data.msg}`, "error");
            })
            .catch((er) => {
              console.log(er);
            });
        }
      });
    },
    deleteMultiple() {
      let ids_array = [];
      var items = document.getElementsByName("chk_child");
      items.forEach(function (ele) {
        if (ele.checked == true) {
          var trNode = ele.parentNode.parentNode.parentNode;
          var id = trNode.querySelector(".id a").innerHTML;
          ids_array.push(id);
        }
      });
      if (typeof ids_array !== "undefined" && ids_array.length > 0) {
        if (confirm("Are you sure you want to delete this?")) {
          var cusList = this.data;
          ids_array.forEach(function (id) {
            cusList = cusList.filter(function (orders) {
              return orders.id != id;
            });
          });
          this.data = cusList;
          document.getElementById("checkAll").checked = false;
          var itemss = document.getElementsByName("chk_child");
          itemss.forEach(function (ele) {
            if (ele.checked == true) {
              ele.checked = false;
              ele.closest("tr").classList.remove("table-active");
            }
          });
        } else {
          return false;
        }
      } else {
        Swal.fire({
          title: "Please select at least one checkbox",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    addModal() {
      this.temp_data = {};
      this.submitted = false;
      document.getElementById("editModalLabel").innerHTML = "新增部落格文章";
      document.getElementById("add-btn").style.display = "block";
      document.getElementById("edit-btn").style.display = "none";
    },
    showdetail(itemData) {
      this.temp_data = itemData;
      console.log(itemData);
    },
    setPages() {
      let numberOfPages = Math.ceil(this.data.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    closeModal() {
      document.getElementById("close-modal").click();
    },

    // api
    get_all() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "article")
        .then((result) => {
          if (result.data.status == 0) {
            return (this.result_msg = result.data.msg);
          }

          result.data.data.forEach((item) => {
            // article option
            this.article_data.push({ value: item.id, label: item.title });
          });

          this.data = result.data.data;
        })
        .catch((error) => {
          this.result_msg = error.response.data.msg;
        });
    },
    get_all_tags() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "tag")
        .then((result) => {
          if (result.data.status == 0) {
            return (this.result_msg = result.data.msg);
          }

          result.data.data.forEach((item) => {
            // tag option
            this.tag_data.push({ value: item.id, label: item.name });
          });
        })
        .catch((error) => {
          this.result_msg = error.response.data.msg;
        });
    },
    get_categories() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + "article_category")
        .then((result) => {
          if (result.data.status == 0) {
            return (this.result_msg = result.data.msg);
          }
          // category opotions
          result.data.data.forEach((item) => {
            this.category_data.push({ value: item.id, label: item.name });
          });
        })
        .catch((error) => {
          this.result_msg = error.response.data.msg;
        });
    },
    create_data() {
      this.submitted = true;

      let formData = new FormData();
      formData.append("title", this.temp_data.title || "");
      formData.append("url_name", this.temp_data.url_name || "");
      formData.append("content", this.temp_data.content || "");
      formData.append(
        "meta_description",
        this.temp_data.meta_description || ""
      );
      formData.append(
        "tags",
        JSON.stringify(this.temp_data.tag_selected) || ""
      );
      formData.append("category_id", parseInt(this.temp_data.category_id));
      formData.append(
        "pre_article_id",
        parseInt(this.temp_data.pre_article_id)
      );
      formData.append(
        "next_article_id",
        parseInt(this.temp_data.next_article_id)
      );
      let photo = document.getElementById("image").files[0];
      formData.append("image", photo);

      axios
        .post(process.env.VUE_APP_BACKEND_URL + "article", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          if (result.data.status == 0) {
            this.closeModal();
            this.result_msg_variant = "danger";
            return (this.result_msg = result.data.msg);
          }
          // tag
          // let tag_str = "";
          // result.data.data.tags.forEach((tag_item) => {
          //   tag_str = `${tag_str}${tag_item.name},`;
          // });
          // result.data.data.tags = tag_str.substring(0, tag_str.length - 1);

          this.data.push(result.data.data);
          this.article_data.push({
            value: result.data.data.id,
            label: result.data.data.title,
          });
          this.temp_data = {};
          document.getElementById("image").value = "";
          this.result_msg = result.data.msg;
          this.result_msg_variant = "success";
          this.submitted = false;
          this.closeModal();
        })
        .catch((error) => {
          this.result_msg_variant = "danger";
          this.result_msg = error.response.data.msg;
          this.form_errors = error.response.data.errors;
        });
    },
  },
  beforeMount() {
    this.get_all();
    this.get_all_tags();
    this.get_categories();
  },

  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert v-model="result_msg" :variant="result_msg_variant" dismissible>
      {{ result_msg }}
    </b-alert>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center flex-wrap gap-2">
              <div class="flex-grow-1">
                <button
                  class="btn btn-primary add-btn me-1"
                  data-bs-toggle="modal"
                  href="#editModal"
                  @click="addModal"
                >
                  <i class="ri-add-fill me-1 align-bottom"></i> 新增
                </button>
              </div>
              <div class="flex-shrink-0">
                <div class="hstack text-nowrap gap-2">
                  <!-- <button class="btn btn-soft-danger" @click="deleteMultiple">
                    <i class="ri-delete-bin-2-line"></i>
                  </button> -->
                  <!-- <button class="btn btn-danger">
                    <i class="ri-filter-2-line me-1 align-bottom"></i> 篩選
                  </button>
                  <button class="btn btn-soft-success">匯入</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-xxl-12">
        <div class="card" id="companyList">
          <div class="card-header">
            <div class="row g-2">
              <div class="col-md-3">
                <div class="search-box">
                  <input
                    type="text"
                    class="form-control search"
                    placeholder="搜尋..."
                    v-model="searchQuery"
                  />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <div class="col-md-auto ms-auto">
                <div class="d-flex align-items-center gap-2">
                  <span class="text-muted flex-shrink-0">排序: </span>
                  <Multiselect
                    class="form-control"
                    style="width: 150px"
                    v-model="sortby"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="true"
                    :options="[
                      { value: 'title', label: '標題' },
                      { value: 'updated_at', label: '更新日期' },
                      { value: 'created_at', label: '創建日期' },
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card mb-3">
                <table
                  class="table align-middle table-nowrap mb-0"
                  id="customerTable"
                >
                  <thead class="table-light">
                    <tr>
                      <!-- 多選框框 -->
                      <!-- <th scope="col" style="width: 50px">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkAll"
                            value="option"
                          />
                        </div>
                      </th> -->
                      <th class="sort" data-sort="title" scope="col">標題</th>
                      <th class="sort" data-sort="updated_at" scope="col">
                        更新日期
                      </th>
                      <th class="sort" data-sort="created_at" scope="col">
                        創建日期
                      </th>
                      <th scope="col">操作</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(itemData, index) of resultQuery" :key="index">
                      <td>{{ itemData.title }}</td>
                      <td>{{ itemData.updated_at }}</td>
                      <td>{{ itemData.created_at }}</td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li
                            class="list-inline-item"
                            data-bs-toggle="modal"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="View"
                            href="#showModal"
                            @click="showdetail(itemData)"
                          >
                            <a href="javascript:void(0);"
                              ><i
                                class="ri-eye-fill align-bottom text-muted"
                              ></i
                            ></a>
                          </li>
                          <li
                            class="list-inline-item"
                            data-bs-toggle="modal"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Edit"
                            href="#editModal"
                            @click="editModal(itemData)"
                          >
                            <a class="edit-item-btn"
                              ><i
                                class="ri-pencil-fill align-bottom text-muted"
                              ></i
                            ></a>
                          </li>
                          <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Delete"
                            @click="deletedata(itemData)"
                          >
                            <a class="remove-item-btn">
                              <i
                                class="ri-delete-bin-fill align-bottom text-muted"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="noresult" class="mt-4" v-show="!resultQuery.length">
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">抱歉! 查無資料</h5>
                    <p class="text-muted mb-0">如有問題，請聯繫客服。</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-end mt-3"
              v-if="pages.length > 1"
            >
              <div class="pagination-wrap hstack gap-2">
                <a
                  class="page-item pagination-prev disabled"
                  href="#"
                  v-if="page != 1"
                  @click="page--"
                >
                  上一頁
                </a>
                <ul class="pagination listjs-pagination mb-0">
                  <li
                    :class="{
                      active: pageNumber == page,
                      disabled: pageNumber == '...',
                    }"
                    v-for="(pageNumber, index) in pages"
                    :key="index"
                    @click="page = pageNumber"
                  >
                    <a class="page" href="#">{{ pageNumber }}</a>
                  </li>
                </ul>
                <a
                  class="page-item pagination-next"
                  href="#"
                  @click="page++"
                  v-if="page < pages.length"
                >
                  下一頁
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <!-- crate, update 共用 modal -->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content border-0">
          <div class="modal-header bg-soft-primary p-3">
            <h5 class="modal-title" id="editModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <form>
            <div class="modal-body">
              <div class="row g-3">
                <div class="col-xxl-12">
                  <div>
                    <label for="image" class="form-label">圖片</label>
                    <input
                      class="form-control"
                      id="image"
                      type="file"
                      :class="{
                        'is-invalid': submitted && form_errors.image,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.image">{{
                        form_errors.image[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-12">
                  <div>
                    <label for="title" class="form-label">標題</label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      name="title"
                      placeholder="請輸入標題"
                      v-model="temp_data.title"
                      :class="{
                        'is-invalid': submitted && form_errors.title,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.title">{{
                        form_errors.title[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-12">
                  <div>
                    <label for="url_name" class="form-label">URL名稱</label>
                    <input
                      type="text"
                      class="form-control"
                      id="url_name"
                      name="url_name"
                      placeholder="請輸入自定義URL"
                      v-model="temp_data.url_name"
                      :class="{
                        'is-invalid': submitted && form_errors.url_name,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.url_name">{{
                        form_errors.url_name[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-12">
                  <div>
                    <label for="category_id" class="form-label">文章分類</label>
                    <Multiselect
                      class="form-control"
                      placeholder="請選擇文章分類"
                      v-model="temp_data.category_id"
                      :options="category_data"
                      :searchable="false"
                      :allow-empty="false"
                      :close-on-select="true"
                      :class="{
                        'is-invalid': submitted && form_errors.category_id,
                      }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="form_errors.category_id">{{
                        form_errors.category_id[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-12">
                  <label for="content" class="form-label">詳細內容</label>
                  <div class="ckeditor-classic is-invalid">
                    <ckeditor
                      v-model="temp_data.content"
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>

                  <div class="invalid-feedback">
                    <span v-if="form_errors.content">{{
                      form_errors.content[0]
                    }}</span>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-12">
                  <label for="meta_description" class="form-label"
                    >meta 描述</label
                  >
                  <textarea
                    v-model="temp_data.meta_description"
                    class="form-control"
                    :maxlength="225"
                    rows="3"
                    id="meta_description"
                    placeholder="簡短描述，會使用於 meta description 及 文章列表簡短描述"
                    :class="{
                      'is-invalid': submitted && form_errors.meta_description,
                    }"
                  ></textarea>

                  <div class="invalid-feedback">
                    <span v-if="form_errors.note">{{
                      form_errors.note[0]
                    }}</span>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-12">
                  <label for="tags" class="form-label">tag 標籤</label>
                  <Multiselect
                    class="form-control"
                    placeholder="請選擇tag標籤"
                    mode="tags"
                    v-model="temp_data.tag_selected"
                    :options="tag_data"
                    :searchable="true"
                    :allow-empty="false"
                    :close-on-select="true"
                    :class="{
                      'is-invalid': submitted && form_errors.tags,
                    }"
                  />

                  <div class="invalid-feedback">
                    <span v-if="form_errors.tags">{{
                      form_errors.tags[0]
                    }}</span>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-12">
                  <label class="form-label">前篇文章</label>
                  <Multiselect
                    class="form-control"
                    placeholder="請選擇前篇文章"
                    v-model="temp_data.pre_article_id"
                    :options="article_data"
                    :searchable="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    :class="{
                      'is-invalid': submitted && form_errors.pre_article_id,
                    }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="form_errors.pre_article_id">{{
                      form_errors.pre_article_id[0]
                    }}</span>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-12">
                  <label for="meta_description" class="form-label"
                    >下篇文章</label
                  >
                  <Multiselect
                    class="form-control"
                    placeholder="請選擇下篇文章"
                    v-model="temp_data.next_article_id"
                    :options="article_data"
                    :searchable="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    :class="{
                      'is-invalid': submitted && form_errors.next_article_id,
                    }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="form_errors.next_article_id">{{
                      form_errors.next_article_id[0]
                    }}</span>
                  </div>
                </div>
                <!--end col-->
              </div>
            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  class="btn btn-light"
                  id="closemodal"
                  data-bs-dismiss="modal"
                >
                  取消
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  id="add-btn"
                  @click.prevent="create_data"
                >
                  新增
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  id="edit-btn"
                  @click.prevent="updateData"
                >
                  修改
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- show modal -->
    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      aria-labelledby="showModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content border-0">
          <div class="modal-header bg-soft-primary p-3">
            <h5 class="modal-title" id="showModalLabel">詳細資料</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <div class="modal-body">
            <input type="hidden" id="id" />
            <div class="row g-3">
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">圖片：</label>
                  <img
                    :src="img_url + temp_data.image"
                    :alt="img_url + temp_data.image"
                    class="img-fluid"
                  />
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">標題：</label>
                  {{ temp_data.title }}
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">作者：</label>
                  {{ temp_data.author }}
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">URL：</label>
                  {{ temp_data.url_name }}
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">分類：</label>
                  <span v-if="temp_data.category">{{
                    temp_data.category.name
                  }}</span>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">內容：</label>
                  <div v-html="temp_data.content" class="border p-2"></div>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">meta 描述：</label>
                  <div
                    v-html="temp_data.meta_description"
                    class="border p-2"
                  ></div>
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">tag 標籤：</label>
                  <span
                    class="border m-1 p-1"
                    v-for="(item, index) in temp_data.tags"
                    :key="index"
                    >{{ item.name }}</span
                  >
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">更新時間：</label>
                  {{ temp_data.updated_at }}
                </div>
              </div>
              <!--end col-->
              <div class="col-xxl-12">
                <div>
                  <label class="form-label">創建時間：</label>
                  {{ temp_data.created_at }}
                </div>
              </div>
              <!--end col-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
